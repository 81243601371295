import {cn} from '~/lib/utils'

function SecurityImage({
    imgUrl,
    alt,
    className,
}: {
    imgUrl: string
    alt: string
    className?: string
}) {
    let url = imgUrl
    if (imgUrl.startsWith('https://')) {
        url = imgUrl
    } else {
        url = `/polygon` + imgUrl
    }
    return (
        <img
            src={url}
            alt={alt}
            className={cn(
                'border-foreground bg-background h-12 w-12 rounded-md border object-cover shadow-sm',
                className,
            )}
        />
    )
}

export default SecurityImage

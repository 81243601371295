import * as React from 'react'
import {
    Root as AccordianPrimitiveRoot,
    Item as AccordionPrimitiveItem,
    Header as AccordionPrimitiveHeader,
    Trigger as AccordionPrimitiveTrigger,
    Content as AccordionPrimitiveContent,
} from '@radix-ui/react-accordion'
import {cn} from '~/lib/utils'
import {ChevronDown} from 'lucide-react'
import styles from './Accordian.module.scss'

const AccordionRoot = AccordianPrimitiveRoot

const AccordionItem = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitiveItem>,
    React.ComponentPropsWithoutRef<typeof AccordionPrimitiveItem>
>(({className, ...props}, ref) => (
    <AccordionPrimitiveItem ref={ref} className={cn('border-b', className)} {...props} />
))
AccordionItem.displayName = 'AccordionItem'

const AccordionTrigger = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitiveTrigger>,
    React.ComponentPropsWithoutRef<typeof AccordionPrimitiveTrigger>
>(({className, children, ...props}, ref) => (
    <AccordionPrimitiveHeader className="mb-0 flex">
        <AccordionPrimitiveTrigger
            ref={ref}
            className={cn(
                'flex flex-1 items-center justify-between py-4 text-left text-sm font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180',
                className,
            )}
            {...props}
        >
            {children}
            <ChevronDown className="text-muted-foreground h-4 w-4 shrink-0 transition-transform duration-200" />
        </AccordionPrimitiveTrigger>
    </AccordionPrimitiveHeader>
))
AccordionTrigger.displayName = AccordionPrimitiveTrigger.displayName

const AccordionContent = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitiveContent>,
    React.ComponentPropsWithoutRef<typeof AccordionPrimitiveContent>
>(({className, children, ...props}, ref) => (
    <AccordionPrimitiveContent
        ref={ref}
        className={cn(styles.animateTarget, 'overflow-hidden text-sm')}
        {...props}
    >
        <div className={cn('pb-0 pt-0', className)}>{children}</div>
    </AccordionPrimitiveContent>
))
AccordionContent.displayName = AccordionPrimitiveContent.displayName

interface AccordianProps {
    items: {label: string; content: React.ReactNode}[]
    className?: string
}

export const Accordian: React.FC<AccordianProps> = ({className, items}) => {
    return (
        <AccordionRoot type="single" collapsible className={cn(styles.accordian, className)}>
            {items.map((item, index) => (
                <AccordionItem key={index} value={item.label} className={styles.item}>
                    <AccordionTrigger className={styles.trigger}>{item.label}</AccordionTrigger>
                    <AccordionContent className={styles.content} asChild>
                        <div className="mx-4 my-5">{item.content}</div>
                    </AccordionContent>
                </AccordionItem>
            ))}
        </AccordionRoot>
    )
}
